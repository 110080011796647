import SignupScreen from '../components/SignupScreen';

function MainContainer(props: any) {

    return (
        <>
            <SignupScreen />
        </>
    )
}

export default MainContainer;