export const environment = (): string => { 
    return "prod";
};

export const getEnvironmentUrl = (url?: string) => {

    // if(url && url.includes('async-svc')){
    //     return "http://localhost:80"
    // }

    const env = environment();
    if (url && url.includes('async-svc')) {
        return `https://aauti-admin-services-${env}.aauti-backend.com`
    }else if (url && url.includes('content-svc')){
        return `https://aauti-content-services-${env}.aauti-backend.com`
    }else  if (url && url.includes('core-svc')) {
        return `https://aauti-core-services-${env}.aauti-backend.com`
    }
    
  }
  
  