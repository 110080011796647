import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { LoginService } from "../../../services/login.service";

interface SignupStudentState {
  signupUserProfile: any;
  status: "idle" | "loading" | "succeeded" | "failed" | "logout";
  error: any;
}

const initialState: SignupStudentState = {
  signupUserProfile: {},
  status: "idle",
  error: null
} as SignupStudentState;


export const checkSignupUserLogin = createAsyncThunk(
  "user/signup/student",
  async (data: {userId: string, classCode: string}) => {
    const userService = new LoginService();
    const response = await userService.loginWithSignupStudentData(data.userId, data.classCode);
    return response;
  }
);

export const signupUserSlice: any = createSlice({
  name: "signupUser",
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(checkSignupUserLogin.pending, (state) => {
        state.status = "loading";
      })
      .addCase(checkSignupUserLogin.fulfilled, (state, action: PayloadAction<any>) => {
        console.log('action.payload  ', action.payload)
        if (action.payload.status === 200) {
          state.status = "succeeded";
          state.signupUserProfile = {
            ...action.payload.result,
            token: action.payload.token,
          };
        } else {
          state.status = "failed";
          state.error =
            "The email or password you entered doesn't match our records.Please double-check and try again.";
        }
      })
      .addCase(checkSignupUserLogin.rejected, (state, action: any) => {
        state.status = "failed";
        state.error = action.error.message;
      })
});

export default signupUserSlice.reducer;
