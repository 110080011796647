import "bootstrap/dist/css/bootstrap.min.css";
import "../src/assets/css/style.css"

import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom'
import { AppProvider } from './store/AppContext'
import MainContainer from './containers/MainContainer';
import EventSignupCreatePasswordContainer from "./containers/EventSignupCreatePasswordContainer";

function App() {
  
  return (
    <div>
      <AppProvider>
        <Router>
          <Switch>
            <Route exact path="/" render={(props) => <MainContainer {...props}/>} />
            <Route exact path="/verify" render={(props) => <EventSignupCreatePasswordContainer {...props}/>} />
            <Redirect to="/" />
          </Switch>
        </Router>    
      </AppProvider> 
    </div>
  );
}


export default App;
