//at the end of the URL mention '?' for the query parameter that will be added in the base service
//if the url already have atleast one query parameter in it, at the end of the URL mention '&' 
import { BaseService } from "./base.service";
import { environment } from "../config/config";

export class LoginService extends BaseService {
  public async userLogin(data: any) {
    const url = "/core-svc/auth/login";
    return await this.postData(url,{user: data})
  }

  public async getUserGoogleData(accessToken: string) {
    const url = "https://www.googleapis.com/oauth2/v3/userinfo"
    return await this.postDataWithHeaders(url, accessToken)
  }

  public async loginWithCleverData(data: any) {
    const url = "/core-svcauth/clever/login"
    return await this.postData(url, data)
  }

  public async loginWithClassLinkSSOData(code: string) {
    const url = `/async-svc/classlink/user/login?code=${code}`
    return await this.getData(url)
  }

  public async loginWithSignupStudentData(userId: string, classCode: string) {
    const url = `/async-svc/teacher-signup/student/login?userId=${userId}&classCode=${classCode}`
    return await this.getData(url)
  }
}
