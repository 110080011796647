import React, { useContext, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../redux/hook";
import AppContext from "../store/AppContext";
import { accessEmailValidation, clearCompleteSignupDetails, clearSignUpData, clearValidateEventSignUpEmailDetails, completeEventSignUp, validateEventSignUpEmail } from "../redux/features/user/user.slice";
import { Box, Button, Modal, Typography } from "@mui/material";
import linkExpired from "../assets/images/linkExpired.png";
import { clearStorage } from "../store/storage";
import CreatePasswordModal from "../components/CreatePasswordModal";
var qs = require('qs');

const modalStyle = {
    position: 'absolute',
    top:"15%",
    left: '50%',
    transform: 'translate( -50%,0)',
    width: '100%',
    maxWidth: '520px',
    bgcolor: 'background.paper',
    borderRadius: '4px',
    boxShadow: 24,
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    '@media screen and (max-width: 768px)': { 
        width: '95%', 
        maxWidth: '95%', 
      }
  };

const EventSignupCreatePasswordContainer = ({props}:any)=>{

    const dispatch = useAppDispatch();
    const appContext = useContext(AppContext);

    const user = useAppSelector((state : any) => state.user)
    const hostname = window && window.location && window.location.hostname;
    const [showErr,setShowErr] = useState<any>(false)
    const [showCreatePasswordModal, setShowCreatePasswordModal] = useState<any>(false)

    const searchParams = qs.parse(window.location.search);
    const validationCode = searchParams["?eventSignupEmailVerify"]

    useEffect(() => {
        dispatch(validateEventSignUpEmail({validationCode}))
    }, [])

    useEffect(() => {
        if(user.validateEventSignUpEmailDetails.status === "succeeded"){
            setShowCreatePasswordModal(true)
            dispatch(clearValidateEventSignUpEmailDetails())
        }else if (user.validateEventSignUpEmailDetails.status === "failed"){
            setShowErr(true)
            dispatch(clearValidateEventSignUpEmailDetails())
        }
    },[user.validateEventSignUpEmailDetails])

    useEffect(() => {
        if(user?.completeEventSignUpDetails?.status === 'succeeded') {
            setShowCreatePasswordModal(false)
            const userData = user?.completeEventSignUpDetails?.status === "succeeded" && getSignupData() 
            if((userData?.role)?.toLowerCase() === "teacher"){
                const userDetails = { ...userData}
                const convertedUserDetails = jsonToBase64(userDetails)
                // appContext?.setTeacherUserDetails(userDetails, "teacherUserDetails");
                if(hostname.includes('signup')) {
                    window.open(`https://prod.aautischool.com?userDetails=${convertedUserDetails}`,"_self")
                } else {
                    window.open(`http://dev.aautischool.com?userDetails=${convertedUserDetails}`,"_self")
                }
            }
            dispatch(clearCompleteSignupDetails())
        }
    }, [user])

    const getSignupData = () => { 
        const signupData = user?.completeEventSignUpDetails?.data
        const newData = {...signupData.result, districtId:signupData.districtId, schoolId:signupData.schoolId, employeeId:signupData?.result?.sourcedId, sections:[], selectedSection: {}}
        return newData
    }

    const jsonToBase64 = (object: any) => {
        const json = JSON.stringify(object);
        return Buffer.from(json).toString("base64");
    }

    const clickBtn = ()=>{
        setShowErr(false)
        clearStorage()
         if(hostname.includes('signup')) {
            window.open("https://prod.aautischool.com","_self")
        } else {
            window.open("https://dev.aautischool.com","_self")
        }
    }

    const Loading = () => {
        return(
            <div className="w-100 d-flex justify-content-center align-items-center" style={{backgroundColor:"rgba(250,250,250,0.6)", position:"absolute", height:"100vh", zIndex:1}}>
                <div className="spinner-border text-primary ">
                </div>
            </div>
        )
    }

    const submitPassword = (password: string) => {
        dispatch(completeEventSignUp({verificationCode: validationCode, password}))
    }

    return(
        <div>
           {(user?.validateEventSignUpEmailDetails?.status === "loading" || user?.completeEventSignUpDetails?.status === "loading" || user?.completeEventSignUpDetails?.status === 'succeeded') && Loading()}
           {showErr &&  
            <Modal  open={showErr}>
                <Box sx={[modalStyle,{height: "70vh"}]}>
                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", textAlign:"center",flexDirection:"column",p: 5 }}>
                        <img src={linkExpired} style={{height:"300px",width:"300px",textAlign:"center", marginBottom:'5px'}}/>
                        <Typography paragraph m={0} sx={{color:'#3166ba', fontSize: 17, alignSelf: "center", textAlign: "center", }}>
                            An account was already created using this link. 
                        </Typography>
                        <Box sx={{display:'flex', alignItems:'center', mt:2}}>
                            <Button variant="contained" onClick={clickBtn} sx={{textTransform: "none"}}>Click here to Sign In</Button>
                        </Box>
                    </Box>
                </Box>
            </Modal>}
            {!showErr&& showCreatePasswordModal && user?.completeEventSignUpDetails?.status !== "loading" && <CreatePasswordModal openCreatePasswordModal={showCreatePasswordModal} submitPassword={submitPassword}/>}
        </div>
    )
}

export default EventSignupCreatePasswordContainer