import React, { useEffect, useState } from 'react';
import { Modal, Box, Typography, Button, CardMedia, OutlinedInput, Grid,TextField ,InputAdornment} from '@mui/material';
import aautiSchoolLogo from '../assets/images/aautischoolLogo.svg'
import { Visibility, VisibilityOff } from '@mui/icons-material';

const CreatePasswordModal = (props:any) => {

  const {openCreatePasswordModal, submitPassword} = props

  const [createPassword,setCreatePassword]=useState<any>("")
  const [confirmpassword, setConfirmPassword]=useState<any>("")
  const [createPasswordErr,setCreatePasswordErr]=useState<any>(false)
  const [confirmPasswordErr,setConfirmPasswordErr]=useState<any>(false)
  const [showCreatePassword,setShowCreatePassword]=useState<any>(false)
  const [showConfirmPassword,setShowConfirmPassword]=useState<any>(false)

  const [allOk, setAllOk] = useState<any>(false)

  const passwordRegex = /^(?=.*?[#?!@$%^&*-]).{6,}$/

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    maxWidth: '800px',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p:4,
    borderRadius: '4px',
    '@media screen and (max-width: 768px)': { 
      width: '95%', 
      maxWidth: '90%', 
    }
  };

  useEffect(() => {
    if(createPassword !== ""){
      if (!passwordRegex.test(createPassword)) {
        setCreatePasswordErr(true);
        setAllOk(false)
      } else {
        setCreatePasswordErr(false);
      }
    }

    if(confirmpassword !== ""){
      if(confirmpassword !== createPassword){
        setConfirmPasswordErr(true)
        setAllOk(false)
      }else{
        setConfirmPasswordErr(false)
        setAllOk(true)
      }
    }

  },[createPassword, confirmpassword])

  const handleSubmit = (event: any) => {
    event.preventDefault();
    submitPassword(createPassword)
  }

  return (
    <Modal open={openCreatePasswordModal}>
      <Box sx={[modalStyle, { height: '500px'}]}>
      <Box sx={{display:'flex', flexDirection:'column', alignItems:'center', marginTop:'3%', }}>
            <Box sx={{width:'15%'}}>
                <CardMedia
                    component="img"
                    sx={{ width: '100%' }}
                    image={aautiSchoolLogo}
                    alt='Aauti School Logo'
                />
            </Box>
            <Typography component="h1" variant="h5" style={{textAlign:"center", paddingTop:'3%', paddingLeft:'6%', paddingRight:'6%', fontFamily: "sans-serif", fontSize:'16px'}}>Create a password for free premium access to Aauti School and to redeem your Amazon gift card worth $10 - $50</Typography>
        </Box>
        <Grid item xs={12} md={5} sx={{display:'flex', flexDirection:'column', alignItems:'center'}}>
                <Box sx={{display: 'flex', flexDirection: 'column', p:1}}>
                    <Typography sx={{ fontSize: 13, mt: 1, mb: "5px", color:'#000000', fontFamily: "sans-serif" }}>Create your password</Typography>
                    {/* <TextField id="outlined-basic" 
                        onChange={(e:any) => setCreatePassword(e.target.value)} 
                        className="outLined"
                        value={createPassword}
                        size="small"
                        required
                        fullWidth
                        inputProps={{ maxLength: 40 }}
                        placeholder='Enter your password'
                        sx={{ fontSize: 10, backgroundColor:'#ffffff'}}
                        type={showCreatePassword ? "text" : "password"}
                        InputProps={{
                             endAdornment: <InputAdornment position="end">{showCreatePassword ? <Visibility style={{cursor:'pointer'}} fontSize="small" onClick={()=>setShowCreatePassword(false)}/>:<VisibilityOff style={{cursor:'pointer'}} fontSize="small" onClick={()=>setShowCreatePassword(true)} />}</InputAdornment>,
                        }}
                    /> */}

                              <OutlinedInput 
                                onChange={(e) => setCreatePassword(e.target.value)} className="outLined"
                                value={createPassword}
                                size="small"
                                required
                                fullWidth
                                inputProps={{ maxLength: 40 }}
                                placeholder='Enter your password'
                                type={showCreatePassword ? "text" : "password"}
                                sx={{ fontSize: 11, backgroundColor:'#ffffff'}}
                                  endAdornment = {
                                    <InputAdornment position="end">
                                      {showCreatePassword ? (
                                        <Visibility style={{cursor:'pointer'}} fontSize="small" onClick={()=>setShowCreatePassword(false)}/>
                                      ) : (
                                        <VisibilityOff style={{cursor:'pointer'}} fontSize="small" onClick={()=>setShowCreatePassword(true)} />
                                      )}
                                    </InputAdornment>
                                  }
                            />
                    {createPasswordErr ? <Typography sx={{color:"red", fontSize: 12, paddingTop:"5px", fontFamily:'AmazonEmber-Medium'}}>Your password must be 6 characters long and contain 1 special character.</Typography>: 
                    <Typography sx={{color: "#3166ba", fontSize: 12, paddingTop:"5px", fontWeight:'550', fontFamily:'AmazonEmber-Medium' }}>Your password must be 6 characters long and contain 1 special character.</Typography>}

                    <Typography paragraph sx={{ fontSize: 13, mt: 1, mb: "5px", color:'#000000', fontFamily: "sans-serif", }}>Confirm your password</Typography>
                    {/* <TextField id="outlined-basic" 
                        onChange={(e:any) => setConfirmPassword(e.target.value)} 
                        className="outLined"
                        disabled={!createPassword || createPasswordErr}
                        value={confirmpassword}
                        size="small"
                        required
                        fullWidth
                        inputProps={{ maxLength: 40 }}
                        placeholder='Confirm your password'
                        sx={{ fontSize: 10, backgroundColor:'#ffffff'}}
                        type={showConfirmPassword? "text" : "password"}
                        InputProps={{
                          endAdornment: <InputAdornment position="end">{showConfirmPassword?<Visibility style={{cursor:'pointer'}} fontSize="small" onClick={()=>setShowConfirmPassword(false)}/>:<VisibilityOff style={{cursor:'pointer'}} fontSize="small" onClick={()=>setShowConfirmPassword(true)} />}</InputAdornment>,
                        }}
                    /> */}
                    <OutlinedInput
                                onChange={(e) => setConfirmPassword(e.target.value)} className="outLined"
                                value={confirmpassword}
                                size="small"
                                required
                                fullWidth
                                type={showConfirmPassword? "text" : "password"}
                                disabled={!createPassword || createPasswordErr}
                                inputProps={{ maxLength: 40 }}
                                placeholder='Confirm your password'
                                sx={{ fontSize: 11, backgroundColor:'#ffffff'}}
                                endAdornment ={ <InputAdornment position="end">{showConfirmPassword?<Visibility style={{cursor:'pointer'}} fontSize="small" onClick={()=>setShowConfirmPassword(false)}/>:<VisibilityOff style={{cursor:'pointer'}} fontSize="small" onClick={()=>setShowConfirmPassword(true)} />}</InputAdornment>}
                            />
                    {confirmPasswordErr && <Typography sx={{color:"red", fontSize: 12, paddingTop:"5px", fontFamily:'AmazonEmber-Medium'}}>Passwords don't match</Typography>}

                  <Button type="submit" disabled={!allOk} onClick={(e: any) => handleSubmit(e)} fullWidth variant="contained" sx={{ backgroundColor:'#3166ba', textTransform:"none",mt:"5%" }}>Done</Button>
                </Box>
            </Grid>
      </Box>
    </Modal>
  );
};

export default CreatePasswordModal;