import {
  Box,
  Button,
  CardMedia,
  CssBaseline,
  Grid,
  IconButton,
  Link,
  Modal,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import aautiSchoolLogo from "../assets/images/aauti_school.png";
import signupImage from "../assets/images/signup_page_image.png";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../redux/hook";
import {
  apiCreateContactInHubspot,
  apiEventSignup,
  apiSaveUserActivity,
  apiSubmitOtp,
  apiUserVerified,
  apiVerifyPhone,
  clearCompleteSignupDetails,
  clearEventSignupDetails,
  completeEventSignUp,
  apiGetUserActivityDetails,
  apiSaveContactNotes,
  apiUpdateContactStatus
} from "../redux/features/user/user.slice";
import SuccessModal from "./SuccessModal";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import VerifyOtpImgae from "../assets/images/VerifyOtpImage.png";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import otpImage from "../assets/images/otp.jpg";
import OtpInput from "react-otp-input";
import CreatePasswordModal from "./CreatePasswordModal";
import reviewing from "../assets/images/reviewing.png";
import { getItem, saveItem } from "../store/storage";
import { useHistory, useLocation } from "react-router-dom";
var qs = require("qs");

const SignupScreen = () => {
  const [name, setName] = useState<string>("");
  const [schoolName, setSchoolName] = useState<string>("");
  const [schoolEmail, setSchoolEmail] = useState<string>("");
  const [selectedGrades, setSelectedGrades] = useState<any>([]);
  const [curriculum, setCurriculum] = useState<any>("");

  const [nameError, setNameError] = useState<string>("");
  const [schoolNameError, setSchoolNameError] = useState<string>("");
  const [emailError, setEmailError] = useState<any>(false);
  const [schoolEmailError, setSchoolEmailError] = useState("");
  const [refEmail, setRefEmail] = useState<string>("");
  const [emailrefErrors, setEmailRefErrors] = useState<any>(false);
  const [refEmailErrors, setRefEmailErrors] = useState("");
  const [gradesError, setGradesError] = useState<string | null>(null);
  const [curriculumError, setCurriculumError] = useState<string>("");

  const [showSuccessModal, setShowSuccessModal] = useState<any>(false);
  const [showApiError, setShowApiError] = useState<any>(false);
  const [openPhoneNumberModal, setOpenPhoneNumberModal] = useState(false);
  const [phNumber, setPhNumber] = useState<any>("");
  const [phOtp, setPhOtp] = useState(false);
  const [otp, setOtp] = useState("");
  const [phNumberError, setPhNumberError] = useState("");
  const [showCreatePasswordModal, setShowCreatePasswordModal] =
    useState<any>(false);
  const [checkEmail, setCheckEmail] = useState(false);
  const [showOtpError, setShowOtpError] = useState("");
  const userData = useState(getItem("userDetails"));
  const history = useHistory();

  const dispatch = useAppDispatch();
  const eventSignUpDetails = useAppSelector(
    (state: any) => state?.user?.eventSignUpDetails
  );
  const verifyPhoneDetails = useAppSelector(
    (state: any) => state?.user?.verifyPhoneData
  );
  const submitOtpDetails = useAppSelector(
    (state: any) => state?.user?.submitOtpData
  );
  const user = useAppSelector((state: any) => state.user);

  const isSubmitting =
    eventSignUpDetails?.status === "loading" ||
    verifyPhoneDetails?.status === "loading" ||
    submitOtpDetails?.status === "loading";

  const hostname = window && window.location && window.location.hostname;

  const searchParams = qs.parse(window.location.search);
  const emailQueryStrValue = searchParams["email"];

  const isOtpFromQueryStrValue = searchParams["?otp"] === "yes" ? true : false;
  const [isOtpFromQueryStr, setisOtpFromQueryStr] = useState(
    isOtpFromQueryStrValue
  );

  const [userId, setUserId] = useState(null);
  const [userActivityEmail,setUserActivityEmail]=useState<any>("")

  const userDetails=useAppSelector(
    (state: any) => state?.user?.userActivityDetails
  );

  const userDatas=userDetails?.data===""?userDetails?.data[0]:""



 
  


  useEffect(() => {
    setisOtpFromQueryStr(isOtpFromQueryStrValue);
  }, [isOtpFromQueryStrValue]);

  

  const gradesArr = ["K", "1", "2", "3", "4", "5", "6", "7", "8"];

  useEffect(() => {
    if (eventSignUpDetails?.status === "succeeded") {
      if (eventSignUpDetails.data.result.waitingForApproval) {
        setCheckEmail(true);
      } else {
        const data: any = {
          firstName: name,
          grades: selectedGrades,
          email: schoolEmail?.toLowerCase(),
          schoolName: schoolName,
          currentCurriculums: curriculum,
          referenceEmail: refEmail,
        };
        saveItem("userDetails", data);
        dispatch(clearEventSignupDetails());
        setOpenPhoneNumberModal(true);
        setShowApiError(false);
      }
    } else if (eventSignUpDetails?.status === "failed") {
      setShowApiError(true);
      dispatch(clearEventSignupDetails());
    }
  }, [eventSignUpDetails]);

  useEffect(() => {
    if (verifyPhoneDetails?.status === "succeeded") {
      setOpenPhoneNumberModal(false);
      setisOtpFromQueryStr(false);
      setPhOtp(true);
    }
  }, [verifyPhoneDetails]);

  useEffect(() => {
    if (submitOtpDetails?.status === "succeeded") {
      setPhOtp(false);
      setShowCreatePasswordModal(true);
    } else if (submitOtpDetails?.status === "failed") {
      setShowOtpError(submitOtpDetails?.error);
    }
  }, [submitOtpDetails]);

  useEffect(() => {
    if (userData?.[0]?.email) {
      history.push(`?otp=yes&email=${userData?.[0]?.email}`);
    }
  }, []);

  useEffect(() => {
    if (user?.completeEventSignUpDetails?.status === "succeeded") {
      setShowCreatePasswordModal(false);
      const userData =
        user?.completeEventSignUpDetails?.status === "succeeded" &&
        getSignupData();
      if (userData?.role?.toLowerCase() === "teacher") {
        const userDetails = { ...userData };
        const convertedUserDetails = jsonToBase64(userDetails);
        // appContext?.setTeacherUserDetails(userDetails, "teacherUserDetails");
        if (hostname.includes("signup")) {
          window.open(
            `https://prod.aautischool.com?userDetails=${convertedUserDetails}`,
            "_self"
          );
        } else {
          window.open(
            `http://dev.aautischool.com?userDetails=${convertedUserDetails}`,
            "_self"
          );
        }
      }
      dispatch(clearCompleteSignupDetails());
    }
  }, [user]);

  const getSignupData = () => {
    const signupData = user?.completeEventSignUpDetails?.data;
    const newData = {
      ...signupData.result,
      districtId: signupData.districtId,
      schoolId: signupData.schoolId,
      employeeId: signupData?.result?.sourcedId,
      sections: [],
      selectedSection: {},
    };
    return newData;
  };
  const jsonToBase64 = (object: any) => {
    const json = JSON.stringify(object);
    return Buffer.from(json).toString("base64");
  };

  // useEffect(() => {
  //     if (eventSignUpDetails?.status === "succeeded") {
  //         if(eventSignUpDetails.data.result.waitingForApproval){
  //             // not in white & black list screen to be called here
  //           }else{
  //         // setShowSuccessModal(true)
  //         dispatch(clearEventSignupDetails())
  //         setOpenPhoneNumberModal(true);
  //         setShowApiError(false)}
  //     } else if (eventSignUpDetails?.status === "failed") {
  //         setShowApiError(true)
  //         dispatch(clearEventSignupDetails())
  //     }
  // }, [eventSignUpDetails])

  useEffect(() => {
    if (showSuccessModal) {
      setTimeout(() => {
        setOpenPhoneNumberModal(false);
        setShowSuccessModal(false);
        setName("");
        setSchoolName("");
        setSchoolEmail("");
        setSelectedGrades([]);
        setCurriculum("");
        setNameError("");
        setSchoolNameError("");
        setEmailError(false);
        setSchoolEmailError("");
        setGradesError(null);
        setCurriculumError("");
      }, 5000);
    }
  }, [showSuccessModal]);

  const handleGradeSelection = (selectedGrade: any) => {
    let a = [];
    if (selectedGrades.includes(selectedGrade)) {
      a = selectedGrades.filter((grade: any) => grade !== selectedGrade);
    } else {
      a = [...selectedGrades, selectedGrade];
    }

    if (a.length === 0) {
      setGradesError("Select grades");
    } else {
      setGradesError(null);
    }
    setSelectedGrades(a);
  };

  const validateEmail = (email: string): boolean => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailRegex.test(email);
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const email = e.target.value;
    setSchoolEmail(email);

    if (validateEmail(email)) {
      setEmailError(false);
      setSchoolEmailError(schoolEmailError);
    } else {
      setEmailError(true);
      setSchoolEmailError("");
    }
  };
  const handleEmailChanges = (e: React.ChangeEvent<HTMLInputElement>) => {
    const email = e.target.value;
    setRefEmail(email);

    if (validateEmail(email)) {
      setEmailRefErrors(false);
      setRefEmailErrors(schoolEmailError);
    } else {
      setEmailRefErrors(true);
      setRefEmailErrors("");
    }
  };

  const handleBlur = (fieldName: any) => {
    switch (fieldName) {
      case "name":
        if (name === "") {
          setNameError("Please enter your name");
        } else if (name.length < 1) {
          setNameError("Name must be at least 1 character long");
        } else if (name.length > 20) {
          setNameError("Name should be 20 characters long");
        } else {
          setNameError("");
        }
        break;

      case "schoolName":
        if (schoolName === "") {
          setSchoolNameError("Please enter your school name");
        } else if (schoolName.length < 3) {
          setSchoolNameError("School name must be at least 3 characters long");
        } else {
          setSchoolNameError("");
        }
        break;

      case "schoolEmail":
        if (schoolEmail === "") {
          setSchoolEmailError("Please enter your school email");
        } else {
          setSchoolEmailError("");
        }
        break;

      case "curriculum":
        if (curriculum === "") {
          setCurriculumError("Please enter your curriculum");
        } else {
          setCurriculumError("");
        }
        break;
      case "refEmail":
        if (refEmail === "") {
          setRefEmailErrors("Please enter valid email address");
        } else {
          setRefEmailErrors("");
        }
        break;

      default:
        break;
    }
  };

  const submitPassword = (password: string) => {
    dispatch(
      completeEventSignUp({
        email: emailQueryStrValue
          ? emailQueryStrValue
          : schoolEmail?.toLowerCase(),
        password,
      })
    );
  };
  const backCheckEmail = () => {
    setName("");
    setSchoolName("");
    setSchoolEmail("");
    setSelectedGrades([]);
    setCurriculum("");
    setCurriculum("");
    setRefEmail("");
    setCheckEmail(false);
  };


  

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
  
    if (schoolNameError || nameError) {
      return null;
    } else {
      const data = {
        fullName: name,
        grades: selectedGrades,
        email: schoolEmail?.toLowerCase(),
        schoolName: schoolName,
        currentCurriculum: curriculum,
        referenceEmail: refEmail,
      };

      const getCurrentCSTTimeString = (date:any) => {
        const cstOptions:any = {
            timeZone: 'America/Chicago',
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false
        };
    
        const d = new Intl.DateTimeFormat('en-US', cstOptions).format(date);
        return new Date(d).toISOString();
    };
  
      try {
        const response = await dispatch(apiEventSignup(data));
  
        
       
  
        if (response && response?.payload?.status === 201) {
          const userId = response?.payload?.result?.userId;
          const email = response?.payload?.result?.email;
          const source = response?.payload?.result?.source
  
          setUserId(userId);
         
          let data={
            userId: userId,
            verificationSentTime: getCurrentCSTTimeString(new Date()),
            verification2SentTime:null,
            isVerified: false,
            email: email,
            sectionCreatedTime:[],
            studentCreatedTime:[],
            source: source,
            section1stEmailSentTime:null,
            section2ndEmailSentTime:null,
            student1stEmailSentTime:null,
            student2ndEmailSentTime:null,
            firstName:name,
            school:schoolName
          }
  
          await dispatch(apiSaveUserActivity(data));

          await dispatch(apiCreateContactInHubspot({
            email: schoolEmail?.toLowerCase() ,
            accountstatus:"OTP_NOT_VERIFIED",
            firstName:name,
            school:schoolName
          }));
  
        } else {
          console.error("API call failed or did not return status 201");
        }
      } catch (error) {
        console.error("Error in handleSubmit: ", error);
      }
    }
  };


 


  const [emailProcessed, setEmailProcessed] = useState(false);
  const location = useLocation()
  const otpData = useAppSelector(
    (state: any) => state.user?.submitOtpData
  );

  useEffect(() => {
    if (!emailProcessed) {
      const emailToFetch = emailQueryStrValue ? emailQueryStrValue : schoolEmail?.toLowerCase();
      dispatch(apiGetUserActivityDetails({ email: emailToFetch }));
      setEmailProcessed(true); // Mark email as processed
    }
  }, [location.search, emailQueryStrValue, schoolEmail, emailProcessed, dispatch]);


  useEffect(() => {
    if (otpData?.status === 'succeeded') {
      const userDatas = userDetails?.data?.[0] ?? {};
      console.log(userDetails, "Fetched User Details");

      dispatch(apiUserVerified({
        userId: userId || userDatas.userId
      }));

      dispatch(apiUpdateContactStatus({
        email:schoolEmail?.toLowerCase() || userDatas.email?.toLowerCase()

      }))

      dispatch(apiSaveContactNotes({
        email: schoolEmail?.toLowerCase() || userDatas.email?.toLowerCase(),
        message:"ACCOUNT VERIFIED"
        
      }));
    }
  }, [otpData?.status, userDetails, userId, schoolEmail, name, schoolName, phNumber, dispatch]);

  
  

  const submitOtp = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    
    const data = {
      email: emailQueryStrValue ? emailQueryStrValue : schoolEmail?.toLowerCase(),
      phoneNumber: phNumber,
      otp: otp,
    };
  
    setShowOtpError("");
  
    try {
      await dispatch(apiSubmitOtp(data));
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };
  

  const verifyPhone = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = {
      email: emailQueryStrValue
        ? emailQueryStrValue
        : schoolEmail?.toLowerCase(),
      phoneNumber: phNumber,
    };
    await dispatch(apiVerifyPhone(data));
   
   
  };
  const handleOtpChange = (otp: any) => {
    setShowOtpError("");
    setOtp(otp);
  };

  const disableSubmit =
    name === "" ||
    schoolName === "" ||
    schoolEmail === "" ||
    selectedGrades.length === 0 ||
    curriculum === "" ||
    emailError ||
    nameError ||
    schoolNameError;
  const disablePhonenumber = phNumber === "";
  const diableOtp = otp === "";
  const Loading = () => {
    return (
      <div
        className="w-100 d-flex justify-content-center align-items-center"
        style={{
          backgroundColor: "rgba(250,250,250,0.6)",
          position: "absolute",
          height: "100vh",
          zIndex: 1,
        }}
      >
        <div className="spinner-border text-primary "></div>
      </div>
    );
  };
  const openPhoneNumberModalHandler = () => {
    setOpenPhoneNumberModal(true);
    setPhOtp(false); // Open modal with phone number field
  };

  const handlePhoneChange = (e: any) => {
    setPhNumber(e);
  };
  const errorMessageSignup = () => {
    if (
      eventSignUpDetails.error ===
      "This email is already registered, Please Sign In."
    ) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <p
            style={{
              color: "#333",
              fontSize: "12px",
              paddingTop: "15px",
              paddingRight: "5px",
            }}
          >
            This email is already registered, Please
          </p>

          <a
            href="https://prod.aautischool.com/"
            style={{
              color: "blue",
              fontSize: "12px",
              backgroundColor: "",
              textDecoration: "underline",
            }}
          >
            Sign In
          </a>
        </div>
      );
    } else {
      return <p>{eventSignUpDetails.error}</p>;
    }
  };

  return (
    <>
      {/* {showSuccessModal && <SuccessModal showSuccessModal={showSuccessModal} />}
            {eventSignUpDetails?.status === "loading" ? Loading() : */}

      <Grid
        container
        sx={{ height: "100vh", backgroundColor: "#ffffff", px: "7%" }}
      >
        <CssBaseline />

        <Box sx={{ backgroundColor: "#fafafa", px: "7%" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: "3%",
            }}
          >
            <Box sx={{ width: "15%" }}>
              <CardMedia
                component="img"
                sx={{ width: "100%" }}
                image={aautiSchoolLogo}
                alt="Aauti School Logo"
              />
            </Box>
            <Typography
              component="h1"
              variant="h5"
              style={{ textAlign: "center" }}
            >
              Empowering science education through AI innovation
            </Typography>
          </Box>

          {/* {showApiError  && (
                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#ffe4d6", textAlign:"center", height:60, paddingLeft:10, paddingRight:10, mt:1}}>
                        <IconButton>
                            <ReportProblemIcon fontSize="medium" color="warning"/>
                        </IconButton >

                        <Typography paragraph mt={2} sx={{ fontSize: 16, color: "#e04e4e", paddingTop:1.5,paddingBottom:1.5,paddingRight:1 }}>
                            { eventSignUpDetails.error ? eventSignUpDetails.error : 'Oops! It seems like this email address is already registered'}
                        </Typography>
                    </Box>
                )} */}

          {showApiError && (
            <Grid item xs={12}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#ffe4d6",
                  textAlign: "center",
                  height: 30,
                  paddingLeft: 10,
                  paddingRight: 10,
                  paddingTop: 5,
                  paddingBottom: 5,
                  mt: 1,
                  borderRadius: 2,
                  "@media (max-width: 600px)": {
                    padding: "4px",
                    height: "auto",
                  },
                }}
              >
                <IconButton>
                  <ReportProblemIcon fontSize="medium" color="warning" />
                </IconButton>

                <Typography
                  paragraph
                  mt={2}
                  sx={{
                    fontSize: 16,
                    color: "#e04e4e",
                    paddingTop: 1.5,
                    paddingBottom: 1.5,
                    paddingRight: 1,
                  }}
                >
                  {eventSignUpDetails.error
                    ? errorMessageSignup()
                    : "Oops! It seems like this email address is already registered"}
                </Typography>
              </Box>
            </Grid>
          )}

          <Grid container my={1} spacing={2}>
            <Grid item xs={12} md={7} p={2}>
              <img
                src={signupImage}
                alt="Aauti School Logo"
                style={{ width: "100%", height: "100%", borderRadius: "15px" }}
              />
              {/* <CardMedia
                            component="img"
                            sx={{ width: '100%', height:"100%", borderRadius:'15px' }}
                            image={signupImage}
                            alt='Aauti School Logo'
                        />  */}
            </Grid>

            {(openPhoneNumberModal || isOtpFromQueryStr) && (
              <Grid
                xs={12}
                md={5}
                item
                style={{ justifyContent: "center", width: "auto" }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    p: 1,
                    justifyContent: "center",
                  }}
                >
                  <Box
                    style={{ justifyContent: "flex-start", display: "flex" }}
                  >
                    <IconButton
                      color="default"
                      onClick={() => {
                        setOpenPhoneNumberModal(false);
                        setPhOtp(false);
                        setisOtpFromQueryStr(false);
                        history.push("");
                      }}
                      sx={{ backgroundColor: "transparent" }}
                    >
                      <KeyboardBackspaceIcon
                        sx={{ color: "black", fontSize: "24px" }}
                      />
                    </IconButton>
                  </Box>

                  {/* <Typography component="h3" variant="h6" sx={{color:'#121828',fontSize:"14px"}}>To ensure activation of your account and to redeem your Amazon gift card, share your cell phone number for the activation/redemption link</Typography> */}
                  <img
                    src={VerifyOtpImgae}
                    style={{
                      height: "250px",
                      width: "250px",
                      textAlign: "center",
                      margin: "auto",
                    }}
                  />

                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "18px",
                        color: "#3166ba",
                        fontFamily:
                          "HelveticaNeue-Medium,Helvetica Neue Medium,Helvetica Neue, sans-serif",
                        fontWeight: "500",
                        textAlign: "center",
                      }}
                    >
                      Almost there!
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        color: "#3166ba",
                        fontFamily:
                          "HelveticaNeue-Medium,Helvetica Neue Medium,Helvetica Neue, sans-serif",
                        fontWeight: "500",
                        textAlign: "center",
                      }}
                    >
                      Verify your account using OTP (One Time Password)
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        color: "#272727",
                        fontFamily:
                          "HelveticaNeue-Medium,Helvetica Neue Medium,Helvetica Neue, sans-serif",
                        textAlign: "center",
                      }}
                    >
                      Enter your mobile number below to receive a one time
                      verification code.
                    </Typography>

                    <Box sx={{ width: "60%", justifyContent: "center" }}>
                      <Typography
                        paragraph
                        sx={{ fontSize: 13, mt: "10px", mb: "2px" }}
                      >
                        Mobile number
                      </Typography>
                      <PhoneInput
                        defaultCountry="US"
                        international
                        placeholder="Enter phone number"
                        value={phNumber}
                        onChange={handlePhoneChange}
                      />
                    </Box>
                  </Box>

                  <Grid
                    style={{
                      marginTop: "15px",
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <Button
                      onClick={(e: any) => verifyPhone(e)}
                      disabled={disablePhonenumber || isSubmitting}
                      fullWidth
                      variant="contained"
                      sx={{
                        backgroundColor: "#3166ba",
                        textTransform: "none",
                        width: "60%",
                      }}
                    >
                      {isSubmitting ? "Submitting..." : "Continue"}
                    </Button>
                  </Grid>
                </Box>
              </Grid>
            )}

            {phOtp && (
              <Grid
                item
                xs={12}
                md={5}
                style={{
                  justifyContent: "center",
                  width: "auto",
                  alignItems: "center",
                  alignContent: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    p: 1,
                    justifyContent: "center",
                  }}
                >
                  <Box
                    style={{ justifyContent: "flex-start", display: "flex" }}
                  >
                    <IconButton
                      color="default"
                      onClick={() => {
                        setOpenPhoneNumberModal(true);
                        setPhOtp(false);
                      }}
                      sx={{ backgroundColor: "transparent" }}
                    >
                      <KeyboardBackspaceIcon
                        sx={{ color: "black", fontSize: "24px" }}
                      />
                    </IconButton>
                  </Box>

                  {/* <Typography component="h3" variant="h6" sx={{color:'#121828',fontSize:"14px"}}>To ensure activation of your account and to redeem your Amazon gift card, share your cell phone number for the activation/redemption link</Typography> */}
                  <img
                    src={otpImage}
                    style={{
                      height: "200px",
                      width: "200px",
                      textAlign: "center",
                      margin: "auto",
                    }}
                  />

                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        color: "#3166ba",
                        fontFamily:
                          "HelveticaNeue-Medium,Helvetica Neue Medium,Helvetica Neue, sans-serif",
                        fontWeight: "500",
                        textAlign: "center",
                        marginBottom: "5px",
                        marginTop: "5px",
                      }}
                    >
                      Verification Code
                    </Typography>

                    <Typography
                      sx={{
                        fontSize: "13px",
                        color: "#272727",
                        fontFamily:
                          "HelveticaNeue-Medium,Helvetica Neue Medium,Helvetica Neue, sans-serif",
                        textAlign: "center",
                      }}
                    >
                      We have sent the verification code to the phone number you
                      provided
                    </Typography>

                    <Box
                      sx={{
                        marginBottom: "4%",
                        marginTop: "3%",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <div
                        style={{
                          marginTop: "5px",
                          marginBottom: "5px",
                          display: "flex",
                          marginLeft: "5px",
                          justifyContent: "flex-start",
                          fontFamily: "HelveticaNeue-Medium",
                          fontSize: "12px",
                        }}
                      >
                        OTP
                      </div>

                      <OtpInput
                        value={otp}
                        onChange={handleOtpChange}
                        numInputs={4}
                        shouldAutoFocus
                        renderSeparator={<span> </span>}
                        renderInput={(props) => <input {...props} />}
                        containerStyle={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                        inputStyle={{
                          width: "40px", // Adjust the width of each input field
                          height: "40px", // Adjust the height of each input field
                          margin: "0 5px", // Adjust the margin to add separation between input fields
                          fontSize: "16px", // Adjust the font size of the input fields
                          textAlign: "center", // Center align the text inside the input fields
                          border: "1px solid #ced4da", // Add border for each input field
                        }}
                      />
                    </Box>
                    {showOtpError && (
                      <Typography
                        sx={{
                          fontSize: "13px",
                          color: "red",
                          fontFamily:
                            "HelveticaNeue-Medium,Helvetica Neue Medium,Helvetica Neue, sans-serif",
                          textAlign: "center",
                        }}
                      >
                        {showOtpError}
                      </Typography>
                    )}
                    <Typography
                      sx={{
                        fontSize: "13px",
                        color: "#272727",
                        fontFamily:
                          "HelveticaNeue-Medium,Helvetica Neue Medium,Helvetica Neue, sans-serif",
                        textAlign: "center",
                      }}
                    >
                      Didn’t receive the verification OTP?
                    </Typography>
                    <Link
                      component="button"
                      variant="body2"
                      onClick={(e: any) => verifyPhone(e)}
                    >
                      <Typography
                        sx={{
                          fontSize: "13px",
                          color: "#3166ba",
                          fontFamily:
                            "HelveticaNeue-Medium,Helvetica Neue Medium,Helvetica Neue, sans-serif",
                          textAlign: "center",
                        }}
                      >
                        Resend Code
                      </Typography>
                    </Link>
                  </Box>

                  <Grid
                    style={{
                      marginTop: "15px",
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <Button
                      onClick={(e: any) => submitOtp(e)}
                      disabled={diableOtp || isSubmitting}
                      fullWidth
                      variant="contained"
                      sx={{
                        backgroundColor: "#3166ba",
                        textTransform: "none",
                        width: "60%",
                      }}
                    >
                      {isSubmitting ? "Submitting..." : "Sign up"}
                    </Button>
                  </Grid>
                </Box>
              </Grid>
            )}
            {!openPhoneNumberModal &&
              !isOtpFromQueryStr &&
              !phOtp &&
              !checkEmail && (
                <Grid item xs={12} md={5}>
                  <Box sx={{ display: "flex", flexDirection: "column", p: 1 }}>
                    <Typography
                      component="h1"
                      variant="h6"
                      sx={{ color: "#121828" }}
                    >
                      Sign up for free premium access
                    </Typography>

                    <Typography
                      paragraph
                      sx={{ fontSize: 11, mt: 1, mb: "5px" }}
                    >
                      Name
                    </Typography>
                    <OutlinedInput
                      onChange={(e) => {
                        const firstName = e.target.value.replace(
                          /[^A-Za-z\s]/g,
                          ""
                        );
                        if (firstName.length < 1) {
                          setNameError(
                            "First name must be at least 1 character long"
                          );
                          setName(firstName);
                        } else if (firstName.length > 20) {
                          setNameError(
                            "First name should be 20 characters long"
                          );
                          setName(firstName);
                        } else {
                          setName(firstName);
                          setNameError(""); // Reset firstNameError if input is valid
                        }
                      }}
                      className="outLined"
                      onBlur={() => handleBlur("name")}
                      value={name}
                      size="small"
                      required
                      fullWidth
                      inputProps={{ maxLength: 40 }}
                      placeholder="Name"
                      sx={{ fontSize: 11, backgroundColor: "#ffffff" }}
                    />
                    {nameError && (
                      <Typography sx={{ color: "red", fontSize: "10px" }}>
                        {nameError}
                      </Typography>
                    )}

                    <Typography
                      paragraph
                      sx={{ fontSize: 11, mt: 1, mb: "5px" }}
                    >
                      School Name
                    </Typography>
                    <OutlinedInput
                      onChange={(e) => {
                        const schoolName = e.target.value.replace(
                          /[^A-Za-z0-9\s]/g,
                          ""
                        );
                        if (schoolName.length < 3) {
                          setSchoolNameError(
                            "School name must be at least 3 characters long"
                          );
                          setSchoolName(schoolName);
                        } else {
                          setSchoolName(schoolName);
                          setSchoolNameError("");
                        }
                      }}
                      className="outLined"
                      onBlur={() => handleBlur("schoolName")}
                      value={schoolName}
                      size="small"
                      required
                      fullWidth
                      inputProps={{ maxLength: 40 }}
                      placeholder="School Name"
                      sx={{ fontSize: 11, backgroundColor: "#ffffff" }}
                    />
                    {schoolNameError && (
                      <Typography sx={{ color: "red", fontSize: "10px" }}>
                        {schoolNameError}
                      </Typography>
                    )}

                    <Typography
                      paragraph
                      sx={{ fontSize: 11, mt: 1, mb: "5px" }}
                    >
                      Your School Email Address
                    </Typography>
                    <OutlinedInput
                      onChange={handleEmailChange}
                      className="outLined"
                      onBlur={() => handleBlur("schoolEmail")}
                      value={schoolEmail}
                      size="small"
                      required
                      fullWidth
                      inputProps={{ maxLength: 40 }}
                      placeholder="School Email"
                      sx={{ fontSize: 11, backgroundColor: "#ffffff" }}
                    />
                    {schoolEmailError && (
                      <Typography sx={{ color: "red", fontSize: "10px" }}>
                        {schoolEmailError}
                      </Typography>
                    )}
                    {emailError && !schoolEmailError && (
                      <Typography sx={{ color: "red", fontSize: "10px" }}>
                        Please enter a valid email address
                      </Typography>
                    )}

                    <Typography
                      paragraph
                      sx={{ fontSize: 11, mt: 1, mb: "5px" }}
                    >
                      Grades Taught
                    </Typography>
                    <div>
                      {gradesArr.map((each: any, index: any) => {
                        return (
                          <div
                            key={each}
                            style={{
                              display: "inline-block",
                              padding: "8px 13px 8px 13px",
                              borderRight: "1px solid #e9e9e9",
                              borderLeft:
                                index === 0 ? "1px solid #e9e9e9" : "none",
                              borderTop: "1px solid #e9e9e9",
                              borderBottom: "1px solid #e9e9e9",
                              cursor: "pointer",
                              backgroundColor: selectedGrades.includes(each)
                                ? "#3166ba"
                                : "white",
                            }}
                            onClick={() => handleGradeSelection(each)}
                          >
                            <Typography
                              sx={{
                                color: selectedGrades.includes(each)
                                  ? "#fff"
                                  : "#000",
                              }}
                            >
                              {each}
                            </Typography>
                          </div>
                        );
                      })}
                    </div>
                    {gradesError && (
                      <Typography sx={{ color: "red", fontSize: "10px" }}>
                        {gradesError}
                      </Typography>
                    )}

                    <Typography
                      paragraph
                      sx={{ fontSize: 11, mt: 1, mb: "5px" }}
                    >
                      Current Curriculum Used
                    </Typography>
                    <OutlinedInput
                      onChange={(e) => setCurriculum(e.target.value)}
                      className="outLined"
                      onBlur={() => handleBlur("curriculum")}
                      value={curriculum}
                      size="small"
                      required
                      fullWidth
                      placeholder="Curriculum"
                      sx={{ fontSize: 11, backgroundColor: "#ffffff" }}
                    />
                    {curriculumError && (
                      <Typography sx={{ color: "red", fontSize: "10px" }}>
                        {curriculumError}
                      </Typography>
                    )}

                    <Typography
                      paragraph
                      sx={{ fontSize: 11, mt: 1, mb: "5px" }}
                    >
                      Referred By Email Address
                    </Typography>
                    <OutlinedInput
                      onChange={handleEmailChanges}
                      className="outLined"
                      onBlur={() => handleBlur("refEmail")}
                      value={refEmail}
                      size="small"
                      required
                      fullWidth
                      inputProps={{ maxLength: 40 }}
                      placeholder="Email address of person who referred you"
                      sx={{ fontSize: 11, backgroundColor: "#ffffff" }}
                    />
                    {refEmailErrors && (
                      <Typography sx={{ color: "red", fontSize: "10px" }}>
                        {refEmailErrors}
                      </Typography>
                    )}
                    {emailrefErrors && !refEmailErrors && (
                      <Typography sx={{ color: "red", fontSize: "10px" }}>
                        Please enter a valid email address
                      </Typography>
                    )}
                    {refEmail === schoolEmail && refEmail !== "" && (
                      <Typography sx={{ color: "red", fontSize: "10px" }}>
                        Referred By Email Address must be different from your
                        school email address
                      </Typography>
                    )}
                    <Button
                      onClick={(e: any) => handleSubmit(e)}
                      disabled={
                        disableSubmit ||
                        isSubmitting ||
                        (refEmail === schoolEmail && refEmail !== "")
                      }
                      fullWidth
                      variant="contained"
                      sx={{
                        mt: 2,
                        mb: 2,
                        backgroundColor: "#3166ba",
                        textTransform: "none",
                      }}
                    >
                      {isSubmitting ? "Submitting..." : "Next"}
                    </Button>
                  </Box>
                </Grid>
              )}

            {checkEmail && (
              <Grid item xs={2} md={5} sx={{ backgroundColor: "white" }}>
                <Box style={{ justifyContent: "flex-start", display: "flex" }}>
                  <IconButton
                    color="default"
                    onClick={() => backCheckEmail()}
                    sx={{ backgroundColor: "transparent" }}
                  >
                    <KeyboardBackspaceIcon
                      sx={{ color: "black", fontSize: "24px" }}
                    />
                  </IconButton>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    backgroundColor: "white",
                  }}
                >
                  <img
                    src={reviewing}
                    style={{
                      height: "250px",
                      width: "250px",
                      textAlign: "center",
                      margin: "auto",
                    }}
                  />
                </Box>

                <Box style={{ marginTop: "10px" }}>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      color: "#3166ba",
                      fontFamily:
                        "HelveticaNeue-Medium,Helvetica Neue Medium,Helvetica Neue, sans-serif",
                      fontWeight: "500",
                      textAlign: "center",
                    }}
                  >
                    We’re reviewing your request.
                  </Typography>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        color: "#3166ba",
                        fontFamily:
                          "HelveticaNeue-Medium,Helvetica Neue Medium,Helvetica Neue, sans-serif",
                        fontWeight: "500",
                        textAlign: "center",
                      }}
                    >
                      Expect an email soon to{" "}
                    </Typography>
                    <Typography
                      sx={{
                        fontsize: "14px",
                        fontweight: "500",
                        color: "#333333",
                        marginLeft: "5px",
                      }}
                    >
                      {schoolEmail}
                    </Typography>
                  </div>

                  <Typography
                    sx={{
                      fontSize: "14px",
                      color: "#3166ba",
                      fontFamily:
                        "HelveticaNeue-Medium,Helvetica Neue Medium,Helvetica Neue, sans-serif",
                      textAlign: "center",
                    }}
                  >
                    with next steps to access Aauti School.
                  </Typography>
                </Box>
              </Grid>
            )}
          </Grid>
        </Box>
      </Grid>
      {/* } */}
      {showCreatePasswordModal && (
        <CreatePasswordModal
          openCreatePasswordModal={showCreatePasswordModal}
          submitPassword={submitPassword}
        />
      )}
    </>
  );
};

export default SignupScreen;
