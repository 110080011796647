import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { LoginService } from "../../../services/login.service";
import { SignUpService } from "../../../services/signup.service";
interface UsersState {
  userProfile: any;
  status: "idle" | "loading" | "succeeded" | "failed" | "logout";
  error: any;
  sendChangePasswordErrorDetails: any;
  userGoogleData: any;
  cleverLoginData: any;
  signUpData:any;
  verifyPhoneData:any;
  submitOtpData:any;
  emailValidationObj:any;
  accessMailValidationObj:any;
  updateForgotPasswordDetails:any;
  forgotPasswordValidationObj:any;
  eventSignUpDetails: any;
  validateEventSignUpEmailDetails: any;
  completeEventSignUpDetails: any;
  saveUserActivities:any;
  userVerfiedStatus:any;
  saveContactInHubspot:any;
  userActivityDetails:any;
  hubspotContactStatus:any;
  saveHubspotNotes:any,
}

const initialState: UsersState = {
  userProfile: {},
  status: "idle",
  error: null,
  sendChangePasswordErrorDetails: {
    status: "",
    data: {},
    error: null,
  },
  userGoogleData: {
    status: "idle",
    data: {},
    error: null,
  },
  cleverLoginData: {
    status: "idle",
    data: {},
    error: null,
  },
  signUpData:{
    status: "idle",
    data: {},
    error: null,
  },
  verifyPhoneData:{
    status: "idle",
    data: {},
    error: null,
  },
  submitOtpData:{
    status: "idle",
    data: {},
    error: null,
  },
  emailValidationObj:{
    status: "idle",
    data: {},
    error: null
  },
  accessMailValidationObj:{
    status: "idle",
    data: {},
    error: null
  },
  updateForgotPasswordDetails:{
    status: "idle",
    data: {},
    error: null
  },
  forgotPasswordValidationObj:{
    status: "idle",
    data: {},
    error: null
  },
  eventSignUpDetails: {
    status: "idle",
    data: {},
    error: null
  },
  validateEventSignUpEmailDetails: {
    status: "idle",
    data: {},
    error: null
  },
  completeEventSignUpDetails: {
    status: "idle",
    data: {},
    error: null
  },
  saveUserActivities:{
    status: "idle",
    data: {},
    error: null
  },
  userVerfiedStatus:{
    status: "idle",
    data: {},
    error: null
  },
  saveContactInHubspot:{
    status: "idle",
    data: {},
    error: null
  },
  userActivityDetails:{
    status: "idle",
    data: {},
    error: null
  },
  hubspotContactStatus:{
    status: "idle",
    data: {},
    error: null
  },
  saveHubspotNotes:{
    status: "idle",
    data: {},
    error: null
  },
} as UsersState;

export const apiUserLogin = createAsyncThunk(
  "user/clearuserprofile",
  async (data: any) => {
    const loginService = new LoginService();
    const response = await loginService.userLogin(data);
    console.log(response, "response");
    return response;
  }
);

export const apiUserSignUp= createAsyncThunk(
  "user/signup",
  async (data: any) => {
    const loginService = new SignUpService();
    const response = await loginService.userSignUp(data);
    console.log(response, "response");
    return response;
  }
);

export const checkUserEmail = createAsyncThunk(
  "user/forgotpassword",
  async (data: any) => {
    const userService = new SignUpService();
    const response = await userService.checkUserEmail(data);
    console.log(response, "responseee");
    return response;
  }
);

export const getUserGoogleData = createAsyncThunk(
  "user/googledata",
  async (accessToken: any) => {
    const userService = new LoginService();
    const response = await userService.getUserGoogleData(accessToken);
    return response;
  }
);

export const loginWithCleverData = createAsyncThunk(
  "user/cleveraccesstoken",
  async (data: any) => {
    const userService = new LoginService();
    const response = await userService.loginWithCleverData(data);
    return response;
  }
);

export const apiCheckEmailValidation = createAsyncThunk(
  "user/emailValidation",
  async (data: any) => {
    const userService = new SignUpService();
    console.log("datainslicee",data)
    const response = await userService.checkEmailValidation(data);
    return response;
  }
);

export const accessEmailValidation = createAsyncThunk(
  "user/accessEmailValidation",
  async (data: any) => {
    const userService = new SignUpService();
    const response = await userService.emailValidation(data);
    return response;
  }
);

export const apiForgotPasswordValidation = createAsyncThunk(
  "user/accessForgotPasswordValidation",
  async (data: any) => {
    const userService = new SignUpService();
    console.log("datainslicee",data)
    const response = await userService.forgotPasswordValidation(data);
    console.log(response,"respodsee")
    return response;
  }
);

export const updatedPasswordDetails = createAsyncThunk(
  "user/forgotPasswordUpdate",
  async (data: any) => {
    const userService = new SignUpService();
    console.log("datainslicee",data)
    const response = await userService.updateForgotPassword(data);
    console.log(response,"respodsee")
    return response;
  }
);

export const apiEventSignup = createAsyncThunk("user/eventSignup",
  async (data: any) => {
    const signUpService = new SignUpService();
    const response = await signUpService.eventSignUp(data);
    return response
  }
)

export const apiSaveUserActivity= createAsyncThunk(
  "save/user-activity",
  async (data: any) => {
    const signUpService = new SignUpService();
    const response = await signUpService.saveUserActivity(data);
    return response;
  }
);
export const apiVerifyPhone = createAsyncThunk("send/otp",
  async (data: any) => {
    const signUpService = new SignUpService();
    const response = await signUpService.verifyPhone(data);
    return response
  }
)

export const apiUserVerified= createAsyncThunk(
  "user/Verified",
  async (data: any) => {
    const signUpService = new SignUpService();
    const response = await signUpService.userVerified(data);
    return response;
  }
);


export const apiUpdateContactStatus= createAsyncThunk(
  "hubspot/update/contact/status",
  async (data: any) => {
    const signUpService = new SignUpService();
    const response = await signUpService.updateContactStatus(data);
    return response;
  }
);

export const apiSaveContactNotes= createAsyncThunk(
  "save/contact/notes",
  async (data: any) => {
    const signUpService = new SignUpService();
    const response = await signUpService.apiSaveContactNotes(data);
    return response;
  }
);


export const apiCreateContactInHubspot= createAsyncThunk(
  "create/contact",
  async (data: any) => {
    const loginService = new SignUpService();
    const response = await loginService.createContact(data);
    return response;
  }
);


export const apiSubmitOtp = createAsyncThunk("verify/otp",
  async (data: any) => {
    const signUpService = new SignUpService();
    const response = await signUpService.submitOtp(data);
    return response
  }
)

export const apiGetUserActivityDetails = createAsyncThunk("get/useractivity",
  async (data: any) => {
    const signUpService = new SignUpService();
    const response = await signUpService.getUserActivityDetails(data);
    return response
  }
)

export const validateEventSignUpEmail = createAsyncThunk(
  "user/validateEventSignUpEmail",
  async (data: any) => {
    const signUpService = new SignUpService();
    const response = await signUpService.emailValidationForEventSignUp(data);
    return response;
  }
);

export const completeEventSignUp = createAsyncThunk(
  "user/completeEventSignUp",
  async (data: any) => {
    const signUpService = new SignUpService();
    const response = await signUpService.createPasswordAndCompleteEventSignUp(data);
    return response;
  }
);

export const userSlice: any = createSlice({
  name: "user",
  initialState,
  reducers: {
    // clearUserProfile(state, action) {
    //   state.userProfile = {};
    // },
    setStatusIdle: (state) => {
      state.signUpData.status = 'idle';
      state.signUpData.error = null
    },
    clearSignUpData:(state)=>{
      state.accessMailValidationObj.status = 'idle'
    },
    setUpdateForgotPasswordStatusIdle:(state)=>{
      state.updateForgotPasswordDetails.status="idle"
    },
    setValidateForgotPasswordStatusIdle:(state)=>{
      state.forgotPasswordValidationObj.status = "idle"
    },
    clearEventSignupDetails: (state) => {
      state.eventSignUpDetails.status = 'idle'
    },
    clearCompleteSignupDetails: (state) => {
      state.completeEventSignUpDetails.status = 'idle'
    },
    clearValidateEventSignUpEmailDetails: (state) => {
      state.validateEventSignUpEmailDetails.status = "idle"
    }
  },

  extraReducers: (builder) =>
    builder
      .addCase(apiUserLogin.pending, (state) => {
        state.status = "loading";
      })
      .addCase(apiUserLogin.fulfilled, (state, action: PayloadAction<any>) => {
        if (action.payload.statusCode === 200) {
          state.status = "succeeded";
          state.userProfile = {
            ...action.payload.result,
            token: action.payload.token,
          };
        } else {
          console.log("in the full filled");
          state.status = "failed";
          state.error =
            "The email or password you entered doesn't match our records.Please double-check and try again.";
        }
      })
      .addCase(apiUserSignUp.pending, (state) => {
        state.signUpData.status = "loading";
      })
      .addCase(apiUserSignUp.fulfilled, (state, action: PayloadAction<any>) => {
        console.log(action,"actionnn")
        if (action.payload.status === 201) {
          state.signUpData.status = "succeeded";
          state.signUpData.data = action?.payload 
          state.signUpData.error = null

        } else if(action.payload.status === 500){
          console.log("failedd")
          state.signUpData.status = "failed";
          state.signUpData.error = action?.payload?.errorMessage 
        }
        // else {
        //   console.log("in the full filled");
        //   state.status = "failed";
        //   state.error =
        //     "The password you entered doesn't match our records.Please double-check and try again.";
        // }
      })
      .addCase(apiUserSignUp.rejected, (state, action: any) => {
        state.signUpData.status = "failed";
        state.signUpData.error = action?.error?.message;
      })
      .addCase(checkUserEmail.pending, (state, action: PayloadAction<any>) => {
        state.sendChangePasswordErrorDetails.status = "loading";
      })
      .addCase(
        checkUserEmail.fulfilled,
        (state, action: PayloadAction<any>) => {
          console.log(action.payload,"actipnpayloaddd")
          if (action.payload.status === 201) {
            state.sendChangePasswordErrorDetails.status = "succeeded";
            state.sendChangePasswordErrorDetails.data = action.payload.result;
            state.sendChangePasswordErrorDetails.error = null;
          } else {
            state.sendChangePasswordErrorDetails.status = "failed";
            state.sendChangePasswordErrorDetails.error = action.payload.errorMessage;
          }
        }
      )
      .addCase(checkUserEmail.rejected, (state, action: any) => {
        state.sendChangePasswordErrorDetails.status = "failed";
        state.sendChangePasswordErrorDetails.error = action.error.message;
      })
      .addCase(apiForgotPasswordValidation.pending, (state, action: PayloadAction<any>) => {
        state.forgotPasswordValidationObj.status = "loading";
      })
      .addCase(
        apiForgotPasswordValidation.fulfilled,
        (state, action: PayloadAction<any>) => {
          console.log(action.payload,"actipnpayloaddd")
          if (action.payload.status === 201) {
            state.forgotPasswordValidationObj.status = "succeeded";
            state.forgotPasswordValidationObj.data = action.payload;
            state.forgotPasswordValidationObj.error = null;
          }else if(action.payload.status === 500){
            state.forgotPasswordValidationObj.status = "failed";
            state.forgotPasswordValidationObj.error = action.payload.errorMessage;
          }
        }
      )
      .addCase(apiForgotPasswordValidation.rejected, (state, action: any) => {
        state.forgotPasswordValidationObj.status = "failed";
        state.forgotPasswordValidationObj.error = action.error.message;
      })
      .addCase(getUserGoogleData.pending, (state) => {
        state.userGoogleData.status = "loading";
      })
      .addCase(
        getUserGoogleData.fulfilled,
        (state, action: PayloadAction<any>) => {
          if (action?.payload?.email) {
            state.userGoogleData.status = "succeeded";
            state.userGoogleData.data = action.payload;
          } else {
            state.userGoogleData.status = "failed";
            state.userGoogleData.error = "Invalid credentials";
          }
        }
      )
      .addCase(getUserGoogleData.rejected, (state, action: any) => {
        state.userGoogleData.status = "failed";
        state.userGoogleData.error = action.error.message;
      })

      .addCase(loginWithCleverData.pending, (state) => {
        state.cleverLoginData.status = "loading";
      })
      .addCase(
        loginWithCleverData.fulfilled,
        (state, action: PayloadAction<any>) => {
          // console.log('cleverr', action.payload)
          if (action?.payload?.result?.email) {
            state.cleverLoginData.status = "succeeded";
            state.cleverLoginData.data = action.payload.result;
          } else {
            state.cleverLoginData.status = "failed";
            let error = action.payload.error_description
            if(!error) {
              error =  "The email you entered doesn't match our records.Please double-check and try again.";
            }
            state.cleverLoginData.error = error;
          }
        }
      )
      .addCase(loginWithCleverData.rejected, (state, action: any) => {
        state.cleverLoginData.status = "failed";
        state.cleverLoginData.error = action.error.message;
      })

      .addCase(apiCheckEmailValidation.pending, (state, action: PayloadAction<any>) => {
        state.emailValidationObj.status = "loading";
      })
      .addCase(
        apiCheckEmailValidation.fulfilled,
        (state, action: PayloadAction<any>) => {
          console.log(action.payload,"actionaplll")
          // if (action.payload.statusCode === 201) {
            state.emailValidationObj.status = "succeeded";
            console.log(action.payload,"actionpayloadd")
            state.emailValidationObj.data =
              action.payload.result.message;
          }
        // }
      )
      .addCase(apiCheckEmailValidation.rejected, (state, action: any) => {
        state.emailValidationObj.status = "failed";
        state.emailValidationObj.error = action.error.message;
      })
      .addCase(accessEmailValidation.pending, (state, action: PayloadAction<any>) => {
        state.accessMailValidationObj.status = "loading";
      })
      .addCase(
        accessEmailValidation.fulfilled,
        (state, action: PayloadAction<any>) => {
          console.log(action.payload,"actionaplll")
          if (action.payload.status === 201) {
            state.accessMailValidationObj.status = "succeeded";
            console.log(action.payload,"actionpayloadd")
            state.accessMailValidationObj.data=action.payload
            state.accessMailValidationObj.error = null
          }else if(action.payload.status === 500){
          state.accessMailValidationObj.status = "failed"
          state.accessMailValidationObj.error = action.payload.errorMessage
         }
        }
      )
      .addCase(accessEmailValidation.rejected, (state, action: any) => {
        state.accessMailValidationObj.status = "failed";
      })
      .addCase(updatedPasswordDetails.pending, (state, action: PayloadAction<any>) => {
        state.updateForgotPasswordDetails.status = "loading";
      })
      .addCase(
        updatedPasswordDetails.fulfilled,
        (state, action: PayloadAction<any>) => {
          console.log(action.payload,"actionaplll")
          if (action.payload.status === 200) {
            state.updateForgotPasswordDetails.status = "succeeded";
            console.log(action.payload,"actionpayloadd")
            state.updateForgotPasswordDetails.data=action.payload.result
            state.updateForgotPasswordDetails.error = null
          }
         else if(action.payload.status === 500){
          state.updateForgotPasswordDetails.status = "failed"
          state.updateForgotPasswordDetails.error = action.payload.errorMessage
         }
        }
      )
      .addCase(updatedPasswordDetails.rejected, (state, action: any) => {
        state.updateForgotPasswordDetails.status = "failed";
      })
      
      .addCase(apiEventSignup.pending, (state) => {
        state.eventSignUpDetails.status = "loading";
      })
      .addCase(apiEventSignup.fulfilled, (state, action: PayloadAction<any>) => {
        if (action.payload.status === 201) {
          state.eventSignUpDetails.status = "succeeded";
          state.eventSignUpDetails.data = action?.payload 
        } else if(action.payload.status === 500){
          state.eventSignUpDetails.status = "failed";
          state.eventSignUpDetails.error = action?.payload?.errorMessage 
        }
      })
      .addCase(apiEventSignup.rejected, (state, action: any) => {
        state.eventSignUpDetails.status = "failed";
        state.eventSignUpDetails.error = action?.error?.message;
      })
      .addCase(apiVerifyPhone.pending, (state) => {
        state.verifyPhoneData.status = "loading";
      })
      .addCase(apiVerifyPhone.fulfilled, (state, action: PayloadAction<any>) => {
        if (action.payload.status === 201) {
          state.verifyPhoneData.status = "succeeded";
          state.verifyPhoneData.data = action?.payload 
        } else if(action.payload.status === 500){
          state.verifyPhoneData.status = "failed";
          state.verifyPhoneData.error = action?.payload?.errorMessage 
        }
      })
      .addCase(apiVerifyPhone.rejected, (state, action: any) => {
        state.verifyPhoneData.status = "failed";
        state.verifyPhoneData.error = action?.error?.message;
      })
      .addCase(apiSubmitOtp.pending, (state) => {
        state.submitOtpData.status = "loading";
      })
      .addCase(apiSubmitOtp.fulfilled, (state, action: PayloadAction<any>) => {
        if (action.payload.status === 201) {
          state.submitOtpData.status = "succeeded";
          state.submitOtpData.data = action?.payload 
        } else if(action.payload.status === 500){
          state.submitOtpData.status = "failed";
          state.submitOtpData.error = action?.payload?.errorMessage 
        }
      })
      .addCase(apiSubmitOtp.rejected, (state, action: any) => {
        state.submitOtpData.status = "failed";
        state.submitOtpData.error = action?.error?.message;
      })
      .addCase(validateEventSignUpEmail.pending, (state, action: PayloadAction<any>) => {
        state.validateEventSignUpEmailDetails.status = "loading";
      })
      .addCase(validateEventSignUpEmail.fulfilled, (state, action: PayloadAction<any>) => {
          if (action.payload.status === 201) {
            state.validateEventSignUpEmailDetails.status = "succeeded";
            state.validateEventSignUpEmailDetails.data = action.payload
          }else if(action.payload.status === 500){
            state.validateEventSignUpEmailDetails.status = "failed"
            state.validateEventSignUpEmailDetails.error = action.payload.errorMessage
         }
        }
      )
      .addCase(validateEventSignUpEmail.rejected, (state, action: any) => {
        state.validateEventSignUpEmailDetails.status = "failed";
        state.validateEventSignUpEmailDetails.error = action?.error?.message;
      })
      .addCase(completeEventSignUp.pending, (state, action: PayloadAction<any>) => {
        state.completeEventSignUpDetails.status = "loading";
      })
      .addCase(completeEventSignUp.fulfilled, (state, action: PayloadAction<any>) => {
          if (action.payload.status === 201) {
            state.completeEventSignUpDetails.status = "succeeded";
            state.completeEventSignUpDetails.data = action.payload
          }else if(action.payload.status === 500){
            state.completeEventSignUpDetails.status = "failed"
            state.completeEventSignUpDetails.error = action.payload.errorMessage
         }
        }
      )
      .addCase(completeEventSignUp.rejected, (state, action: any) => {
        state.completeEventSignUpDetails.status = "failed";
        state.completeEventSignUpDetails.error = action?.error?.message;
      })
      .addCase(apiSaveUserActivity.pending, (state, action: PayloadAction<any>) => {
        state.saveUserActivities.status = "loading";
      })
      .addCase(
        apiSaveUserActivity.fulfilled,
        (state, action: PayloadAction<any>) => {
          if (action.payload.status === 200) {
            state.saveUserActivities.status = "succeeded";
            state.saveUserActivities.data=action.payload.result
            state.saveUserActivities.error = null
          }
         else if(action.payload.status === 500){
          state.saveUserActivities.status = "failed"
          state.saveUserActivities.error = action.payload.errorMessage
         }
        }
      )
      .addCase(apiSaveUserActivity.rejected, (state, action: any) => {
        state.saveUserActivities.status = "failed";
      })
      .addCase(apiUserVerified.pending, (state, action: PayloadAction<any>) => {
        state.userVerfiedStatus.status = "loading";
      })
      .addCase(
        apiUserVerified.fulfilled,
        (state, action: PayloadAction<any>) => {
          if (action.payload.status === 200) {
            state.userVerfiedStatus.status = "succeeded";
            state.userVerfiedStatus.data=action.payload.result
            state.userVerfiedStatus.error = null
          }
         else if(action.payload.status === 500){
          state.userVerfiedStatus.status = "failed"
          state.userVerfiedStatus.error = action.payload.errorMessage
         }
        }
      )
      .addCase(apiUserVerified.rejected, (state, action: any) => {
        state.userVerfiedStatus.status = "failed";
      })
      .addCase(apiGetUserActivityDetails.pending, (state, action: PayloadAction<any>) => {
        state.userActivityDetails.status = "loading";
      })
      .addCase(
        apiGetUserActivityDetails.fulfilled,
        (state, action: PayloadAction<any>) => {
         
            state.userActivityDetails.status = "succeeded";
            state.userActivityDetails.data=action.payload.result
            state.userActivityDetails.error = null
        
        }
      )
      .addCase(apiGetUserActivityDetails.rejected, (state, action: any) => {
        state.userActivityDetails.status = "failed";
      })
      .addCase(apiCreateContactInHubspot.pending, (state, action: PayloadAction<any>) => {
        state.saveContactInHubspot.status = "loading";
      })
      .addCase(
        apiCreateContactInHubspot.fulfilled,
        (state, action: PayloadAction<any>) => {
          if (action.payload.status === 200) {
            state.saveContactInHubspot.status = "succeeded";
            state.saveContactInHubspot.data=action.payload.result
            state.saveContactInHubspot.error = null
          }
         else if(action.payload.status === 500){
          state.saveContactInHubspot.status = "failed"
          state.saveContactInHubspot.error = action.payload.errorMessage
         }
        }
      )
      .addCase(apiCreateContactInHubspot.rejected, (state, action: any) => {
        state.saveContactInHubspot.status = "failed";
      })
      .addCase(apiUpdateContactStatus.pending, (state, action: PayloadAction<any>) => {
        state.hubspotContactStatus.status = "loading";
      })
      .addCase(
        apiUpdateContactStatus.fulfilled,
        (state, action: PayloadAction<any>) => {
         
            state.hubspotContactStatus.status = "succeeded";
            state.hubspotContactStatus.data=action.payload.result
            state.hubspotContactStatus.error = null
        
        }
      )
      .addCase(apiUpdateContactStatus.rejected, (state, action: any) => {
        state.hubspotContactStatus.status = "failed";
      })
      .addCase(apiSaveContactNotes.pending, (state, action: PayloadAction<any>) => {
        state.saveHubspotNotes.status = "loading";
      })
      .addCase(
        apiSaveContactNotes.fulfilled,
        (state, action: PayloadAction<any>) => {
         
            state.saveHubspotNotes.status = "succeeded";
            state.saveHubspotNotes.data=action.payload.result
            state.saveHubspotNotes.error = null
        
        }
      )
      .addCase(apiSaveContactNotes.rejected, (state, action: any) => {
        state.saveHubspotNotes.status = "failed";
      

      })
})


// export const getUserId = (state: RootState) => {
//   return state.user.userProfile ? state.user.userProfile.userId : "";
// };

// export const { clearUserProfile } = userSlice.actions;

export const { setStatusIdle , clearSignUpData, setUpdateForgotPasswordStatusIdle, setValidateForgotPasswordStatusIdle, clearEventSignupDetails, clearCompleteSignupDetails, clearValidateEventSignUpEmailDetails} = userSlice.actions

export default userSlice.reducer;
